










































import {Component, Prop, Vue} from "vue-property-decorator";
import RouteNames from "../../router/RouteNames";
import UserDataDTO from "@/dto/UserDataDTO";
import PayrollFileCategories from "@/constants/PayrollFileCategories";
import FileUpload from "@/components/common/FileUpload.vue";
import UserDTO from "@/dto/UserDTO";
import {excelAccept, pdfAccept} from "@/constants/FileConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import PayrollService from "@/services/PayrollService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import CreatePayrollDetailsRequestDTO from "@/dto/payroll/CreatePayrollDetailsRequestDTO";
import CompanyDTO from "@/dto/company/CompanyDTO";
import Workspace from "@/dto/auth/Workspace";
import {namespace} from "vuex-class";
import Workspaces from "@/state/Workspaces";

const Auth = namespace("Auth");

@Component({
	components: {FileUpload}
})
export default class SelectBusinessPayrollDetailsAction extends Vue {

	private DOCUMENT_ACCEPT = pdfAccept

	private uploadAction = false;

	private successful = false;

	private message = "";


	closeModal() {
		this.$modal.hideAll()
	}

	goToDetails() {
		this.$router.push({
			name: RouteNames.PAYROLL_BUSINESS_EMPLOYEES,
			params: {companyId: this.$route.params.companyId, then: PayrollFileCategories.DETAILS}
		});
		this.closeModal();
	}

	goToGeneralDetails() {
		this.$router.push({
			name: RouteNames.PAYROLL_BUSINESS_GENERAL_DETAILS,
			params: {companyId: this.$route.params.companyId}
		});
		this.closeModal();
	}

	uploadDocuments(){
		this.uploadAction = true;
	}

	onFilesUploaded(files: FileMetaDTO[]) {
		this.message = "";
		const request = new CreatePayrollDetailsRequestDTO(Workspaces.getCurrent.id,files.map(f => f.id))
		PayrollService.savePayrollDetails(request).then(
			success => {
				this.successful = true;
				this.message = "Uploaded";
			},
			error => {
				this.successful = false;
				const param = error?.response?.data?.param ? {param: error?.response?.data?.param} : {};
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(error), param) as string;
			}
		);
	}
}
